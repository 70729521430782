.pro-Form{
    width: 550px;
    max-width: 100%;
}

.card-pro{
    border: 1px solid var(--brand-color) !important;
    padding: 24px 0px;
}

@media (max-width: 500px){
    .pro-Form{
        max-width: 93%;
    }
}