.btncas{
    transition: .5s all;
}

.sm-card{
    padding: 2rem 1rem;
    margin-bottom: 1rem;
    border-radius: 10px;
    border: 1px solid var(--tertiary-color2);
}

